.loader {
  left: calc(5% - 4px);
  top: calc(5% - 4px);
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /*margin: 75px;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-star {
  position: absolute;
  top: calc(50% - 12px);
  width: 50px;
  height: 50px;
}

/*LOADER-2*/

.loader-2 .loader-star {
  position: static;
  width: 50px;
  height: 50px;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-animation: loader-2-star 1s ease alternate infinite;
  animation: loader-2-star 1s ease alternate infinite;
}

.loader-2 .loader-circles {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 4px);
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation: loader-2-circles 1s ease-in-out alternate infinite;
  animation: loader-2-circles 1s ease-in-out alternate infinite;
}

@-webkit-keyframes loader-2-circles {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-box-shadow: 24px -22px #fff200, 30px -15px 0 -3px #fff200,
      31px 0px #fff200, 29px 9px 0 -3px #fff200, 24px 23px #fff200,
      17px 30px 0 -3px #fff200, 0px 33px #fff200, -10px 28px 0 -3px #fff200,
      -24px 22px #fff200, -29px 14px 0 -3px #fff200, -31px -3px #fff200,
      -30px -11px 0 -3px #fff200, -20px -25px #fff200,
      -12px -30px 0 -3px #fff200, 5px -29px #fff200, 13px -25px 0 -3px #fff200;
    box-shadow: 24px -22px #fff200, 30px -15px 0 -3px #fff200, 31px 0px #fff200,
      29px 9px 0 -3px #fff200, 24px 23px #fff200, 17px 30px 0 -3px #fff200,
      0px 33px #fff200, -10px 28px 0 -3px #fff200, -24px 22px #fff200,
      -29px 14px 0 -3px #fff200, -31px -3px #fff200, -30px -11px 0 -3px #fff200,
      -20px -25px #fff200, -12px -30px 0 -3px #fff200, 5px -29px #fff200,
      13px -25px 0 -3px #fff200;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-box-shadow: 25px -22px #fff200, 15px -22px 0 -3px black,
      31px 2px #fff200, 21px 2px 0 -3px black, 23px 25px #fff200,
      13px 25px 0 -3px black, 0px 33px #fff200, -10px 33px 0 -3px black,
      -26px 24px #fff200, -19px 17px 0 -3px black, -32px 0px #fff200,
      -23px 0px 0 -3px black, -25px -23px #fff200, -16px -23px 0 -3px black,
      0px -31px #fff200, -2px -23px 0 -3px black;
    box-shadow: 25px -22px #fff200, 15px -22px 0 -3px black, 31px 2px #fff200,
      21px 2px 0 -3px black, 23px 25px #fff200, 13px 25px 0 -3px black,
      0px 33px #fff200, -10px 33px 0 -3px black, -26px 24px #fff200,
      -19px 17px 0 -3px black, -32px 0px #fff200, -23px 0px 0 -3px black,
      -25px -23px #fff200, -16px -23px 0 -3px black, 0px -31px #fff200,
      -2px -23px 0 -3px black;
  }
}

@keyframes loader-2-circles {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-box-shadow: 24px -22px #fff200, 30px -15px 0 -3px #fff200,
      31px 0px #fff200, 29px 9px 0 -3px #fff200, 24px 23px #fff200,
      17px 30px 0 -3px #fff200, 0px 33px #fff200, -10px 28px 0 -3px #fff200,
      -24px 22px #fff200, -29px 14px 0 -3px #fff200, -31px -3px #e11a2b,
      -30px -11px 0 -3px #fff200, -20px -25px #fff200,
      -12px -30px 0 -3px #fff200, 5px -29px #fff200, 13px -25px 0 -3px #fff200;
    box-shadow: 24px -22px #fff200, 30px -15px 0 -3px #fff200, 31px 0px #fff200,
      29px 9px 0 -3px #fff200, 24px 23px #fff200, 17px 30px 0 -3px #fff200,
      0px 33px #fff200, -10px 28px 0 -3px #fff200, -24px 22px #fff200,
      -29px 14px 0 -3px #fff200, -31px -3px #fff200, -30px -11px 0 -3px #fff200,
      -20px -25px #fff200, -12px -30px 0 -3px #fff200, 5px -29px #fff200,
      13px -25px 0 -3px #fff200;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-box-shadow: 25px -22px #fff200, 15px -22px 0 -3px black,
      31px 2px #fff200, 21px 2px 0 -3px black, 23px 25px #fff200,
      13px 25px 0 -3px black, 0px 33px #fff200, -10px 33px 0 -3px black,
      -26px 24px #fff200, -19px 17px 0 -3px black, -32px 0px #fff200,
      -23px 0px 0 -3px black, -25px -23px #fff200, -16px -23px 0 -3px black,
      0px -31px #fff200, -2px -23px 0 -3px black;
    box-shadow: 25px -22px #fff200, 15px -22px 0 -3px black, 31px 2px #fff200,
      21px 2px 0 -3px black, 23px 25px #fff200, 13px 25px 0 -3px black,
      0px 33px #fff200, -10px 33px 0 -3px black, -26px 24px #fff200,
      -19px 17px 0 -3px black, -32px 0px #fff200, -23px 0px 0 -3px black,
      -25px -23px #fff200, -16px -23px 0 -3px black, 0px -31px #fff200,
      -2px -23px 0 -3px black;
  }
}

@-webkit-keyframes loader-2-star {
  0% {
    -webkit-transform: scale(0) rotate(0deg);
    transform: scale(0) rotate(0deg);
  }
  100% {
    -webkit-transform: scale(0.7) rotate(360deg);
    transform: scale(0.7) rotate(360deg);
  }
}

@keyframes loader-2-star {
  0% {
    -webkit-transform: scale(0) rotate(0deg);
    transform: scale(0) rotate(0deg);
  }
  100% {
    -webkit-transform: scale(0.7) rotate(360deg);
    transform: scale(0.7) rotate(360deg);
  }
}
