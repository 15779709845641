$active-background: #1c6613;
$btn-bg: rgba(255, 255, 255, 0);
//$btn-color: orange;
@import './node_modules/react-big-calendar/lib/sass/styles';

.rbc-toolbar button:active {
  box-shadow: none;
}

.rbc-toolbar button {
  color: unset;
  display: inline-flex;
  margin: unset;
  text-align: unset;
  border: unset;
  line-height: unset;
  white-space: unset;
  border-radius: 25px;
  //padding: unset;
  background-color: unset;
}
